import React, { useEffect } from "react";

// New close on outside hook that can be used by elements that reside in the toplayer
export const useCloseOnOutsideDialog = (
    element: React.RefObject<HTMLDialogElement>,
    closeFunction: () => void,
    disable = false,
): void => {
    useEffect(() => {
        if (element.current && !disable) {
            const clickHandler = (event: MouseEvent | TouchEvent): void => {
                if (event.target === event.currentTarget) closeFunction();
            };

            element.current.addEventListener("mousedown", clickHandler);
            element.current.addEventListener("touchstart", clickHandler);

            return () => {
                element.current?.removeEventListener("mousedown", clickHandler);
                element.current?.removeEventListener("touchstart", clickHandler);
            };
        }
    }, [element, closeFunction, disable]);
};
