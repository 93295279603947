import React, { memo } from "react";
import clsx from "clsx";
import styles from "./Swatch.module.scss";

type Props = {
    className?: string;
    variant?: "default" | "md" | "sm";
    fill?: string;
    image?: string;
    active?: boolean;
    hasGradient?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const Swatch: React.FC<Props> = ({ variant = "default", fill, image, active, hasGradient, className, ...rest }) => {
    return (
        <div
            className={clsx(
                styles.swatch,
                styles[variant],
                hasGradient && styles["has-gradient"],
                active && styles.active,
                className,
            )}
            style={{ backgroundColor: fill ?? "transparent" }}
            {...rest}
        >
            {image && <img src={image} loading="lazy" data-decorative="yes" alt="" />}
        </div>
    );
};

export default memo(Swatch);
