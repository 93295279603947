import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GradeSelectorFilterId } from "../../utils/filterConstants";
import { getCategoryFilterSubCategory } from "../../utils/filterUtils";
import { trackCategoryVisible } from "../../utils/tracking";

export type GradeSelectorCategoryType = { root: string; subCategory?: string; active: boolean };

export type GradeSelectorCategoryRecordType = Record<string, Omit<GradeSelectorCategoryType, "root">>;

export type GradeSelectorFiltersType = {
    [GradeSelectorFilterId.Engine]: string;
    [GradeSelectorFilterId.WheelDrive]: string;
    [GradeSelectorFilterId.Transmission]: string;
    [GradeSelectorFilterId.Colour]: string[];
    category: GradeSelectorCategoryRecordType;
};

export const gradeSelectorFiltersSlice = createSlice({
    name: "gradeSelectorFilters",
    initialState: { category: {} } as GradeSelectorFiltersType,
    reducers: {
        addColourFilterValue(
            state,
            action: PayloadAction<{ filterId: GradeSelectorFilterId.Colour; value: string[] }>,
        ) {
            const { filterId, value } = action.payload;
            state[filterId] = value;
        },
        addFilterValue(
            state,
            action: PayloadAction<{
                filterId: Exclude<GradeSelectorFilterId, GradeSelectorFilterId.Colour>;
                value: string;
            }>,
        ) {
            const { filterId, value } = action.payload;
            state[filterId] = value;
        },
        setFilterCategory(state, action: PayloadAction<{ category: string | undefined; allowClose?: boolean }>) {
            const root = action.payload.category?.split("/")[0];
            const { category, allowClose } = action.payload;

            // First check tracking, only then modify state.
            trackCategoryVisible(state, category);

            if (root) {
                // Add or overwrite current category.
                const closeRoot = allowClose && root === action.payload.category && state.category[root]?.active;
                state.category[root] = {
                    active: !closeRoot, // If root category is already open and allowClose is true, close the root category. Else it remains open.
                    subCategory: getCategoryFilterSubCategory(root, state, category, !!allowClose),
                };
            }

            // Sets active state for other categories to false.
            if (state.category && Object.keys(state.category).length) {
                Object.keys(state.category).forEach((key) => {
                    if (key !== root) {
                        state.category[key].active = false;
                    }
                });
            }
        },
        clearFilterValue(state, action: PayloadAction<{ filterId: GradeSelectorFilterId }>) {
            const { filterId } = action.payload;
            delete state[filterId];
        },
        clearFilters(state) {
            return { category: state.category } as GradeSelectorFiltersType;
        },
    },
});

export const { addColourFilterValue, addFilterValue, clearFilterValue, setFilterCategory, clearFilters } =
    gradeSelectorFiltersSlice.actions;
