import React, { FC, memo } from "react";
import clsx from "clsx";
import type { GradeBodyTypeEngineTransmissionWheeldriveType } from "../../../../../utils/types/Model";
import styles from "./Price.module.scss";
import CashPrice from "./CashPrice";
import MonthlyPrice from "./MonthlyPrice";
import { getColourPrice } from "../../../../../utils/gradeUtils";

const Price: FC<{
    wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType;
    selectedColourId?: string;
    showPromotionsNumbers?: boolean;
    column?: boolean;
}> = ({ wheeldrive, selectedColourId, showPromotionsNumbers, column }) => {
    const { price, car } = wheeldrive;
    const colourPrice = getColourPrice(selectedColourId, wheeldrive);

    return (
        <span className={clsx(styles.price, column && styles.column)}>
            <CashPrice price={price} colourPrice={colourPrice} showPromotionsNumbers={showPromotionsNumbers} />
            <MonthlyPrice car={car} selectedColourId={selectedColourId} price={price} />
        </span>
    );
};

export default memo(Price);
