import React, { FC, memo } from "react";
import { useGradeSelectorFilterCategory } from "../../../../redux/hooks";
import { useVisibleEquipment } from "../../../../utils/gradeHooks";
import { Equipped, GradeBodyTypeType } from "../../../../utils/types/Model";
import { GradeSelectorLabel } from "../../../../utils/labels";
import List from "../../../../../shared-components/css-modules/toyota/atoms/List";
import Icon from "../../../../../shared-components/css-modules/toyota/atoms/Icon";
import Text from "../../../../../shared-components/css-modules/toyota/atoms/Text";
import a11y from "../../../../../shared-components/css-modules/toyota/utils/Accessibility.module.scss";
import styles from "./Item.module.scss";

const EquipmentView: FC<{
    gradeBodyType: GradeBodyTypeType;
    forceFilter?: string;
}> = ({ gradeBodyType, forceFilter }) => {
    // Equipment
    const currentFilter = useGradeSelectorFilterCategory();
    const equipmentInCurrentGroup = useVisibleEquipment(forceFilter ?? currentFilter?.fullPath);
    const equipmentForThisItem = gradeBodyType.equipment;

    if (typeof equipmentInCurrentGroup === "undefined") {
        // Should never happen, except when value in store is not correct
        return null;
    }

    return (
        <List className={styles["equipment-list"]}>
            {equipmentInCurrentGroup
                .filter(({ isKeyFeature }) => isKeyFeature)
                .map((item) => {
                    const equipmentStandard = !!equipmentForThisItem.find(
                        ({ equipped, id }) => item.id === id && equipped === Equipped.Standard,
                    );
                    const equipmentOption = !!equipmentForThisItem.find(
                        ({ equipped, id }) => item.id === id && equipped === Equipped.Option,
                    );

                    return (
                        <li key={item.id} title={item.name} className={styles.fade}>
                            {equipmentStandard ? (
                                <>
                                    <Icon variant="check" sizing="sm" aria-hidden="true" />
                                    <span className={a11y["sr-only"]}>
                                        <GradeSelectorLabel label="gradeSelectorSpecsIncluded" />
                                    </span>
                                </>
                            ) : equipmentOption ? (
                                <>
                                    <Icon variant="customize-black" sizing="sm" aria-hidden="true" />
                                    <span className={a11y["sr-only"]}>
                                        <GradeSelectorLabel label="gradeSelectorSpecsOptional" />
                                    </span>
                                </>
                            ) : (
                                <>
                                    <Icon variant="close" sizing="sm" aria-hidden="true" />
                                    <span className={a11y["sr-only"]}>
                                        <GradeSelectorLabel label="gradeSelectorSpecsUnavailable" />
                                    </span>
                                </>
                            )}
                            <Text variant="support-book" className={styles["equipment-item-name"]}>
                                {item.name}
                            </Text>
                        </li>
                    );
                })}
        </List>
    );
};

export default memo(EquipmentView);
