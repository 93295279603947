import { useCommonSelector } from "../redux/commonStore";

export const useImageWithFallback = (image?: string): string => {
    const { resourcePath, brand } = useCommonSelector((state) => state.commonSettings);

    return getImageWithFallback(resourcePath, image, brand);
};

export const getImageWithFallback = (resourcePath: string, image?: string, brand: string = "toyota"): string => {
    if (typeof image === "string" && image.trim() !== "") {
        return image;
    }

    return `${resourcePath}/${brand}/img/placeholders/fallback.png`;
};
