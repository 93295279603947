import * as DOMUtils from "history/DOMUtils";
import type { GradeSelectorDispatchType, GradeSelectorStateType } from "../redux/store";
import { type FetchFnReturnType } from "../../../../common-deprecated/utils/backendFetch";
import {
    type GradeSelectorSettingsType,
    initGradeSelectorSettings,
} from "../redux/reducers/GradeSelectorSettingsReducer";
import { init as initGradeSelectorModel } from "../redux/slices/GradeSelectorModelSlice";
import { type ModelType } from "../utils/types/Model";
import { type GradeSelectorFinanceType, initFinance, setFinanceState } from "../redux/slices/GradeSelectorFinanceSlice";
import { searchModelMapValue } from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { type CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { AemFmComponent } from "../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { showAemMonthlyRates } from "../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { RequestStatusEnum } from "../../../../common-deprecated/types/CommonTypes";
import { modelIsLcv } from "../../../shared-logic/utils/lcvUtils";
import { LcvFilterInfoType } from "../utils/types/LcvFilter";
import { initGradeSelectorLcvFilters } from "../redux/slices/GradeSelectorLcvFiltersSlice";

// ----------------------------------------------------------------------
// Fetch types
// ----------------------------------------------------------------------
export const FETCH_GRADE_SELECTOR_SETTINGS = "fetchGradeSelectorSettings";
export const FETCH_GRADE_SELECTOR_MODEL = "fetchGradeSelectorModel";
export const FETCH_GRADE_SELECTOR_LCV_FILTERS = "fetchGradeSelectorLcvFilters";
export const FETCH_GRADE_SELECTOR_FINANCE = "fetchGradeSelectorFinance";

export type FetchGradeSelectorSettingsType = {
    fetchType: typeof FETCH_GRADE_SELECTOR_SETTINGS;
    modelId?: string;
    result?: FetchFnReturnType<GradeSelectorSettingsType | null>;
};

export type FetchGradeSelectorModelType = {
    fetchType: typeof FETCH_GRADE_SELECTOR_MODEL;
    settings: GradeSelectorSettingsType;
    modelId?: string;
    result?: FetchFnReturnType<ModelType | null>;
};

export type FetchGradeSelectorLcvFilterType = {
    fetchType: typeof FETCH_GRADE_SELECTOR_LCV_FILTERS;
    modelId: string;
    result?: FetchFnReturnType<LcvFilterInfoType | null>;
};

export type FetchGradeSelectorFinanceType = {
    fetchType: typeof FETCH_GRADE_SELECTOR_FINANCE;
    modelId: string;
    waitForUncachedResults: boolean;
    result?: FetchFnReturnType<GradeSelectorFinanceType["cars"] | null>;
};

export const fetchGradeSelectorSettings = (modelId?: string): FetchGradeSelectorSettingsType => ({
    fetchType: FETCH_GRADE_SELECTOR_SETTINGS,
    modelId,
});

export const fetchGradeSelectorModel = (
    settings: GradeSelectorSettingsType,
    modelId?: string,
): FetchGradeSelectorModelType => ({
    fetchType: FETCH_GRADE_SELECTOR_MODEL,
    settings,
    modelId,
});

export const fetchGradeSelectorLcvFilters = (modelId: string): FetchGradeSelectorLcvFilterType => ({
    fetchType: FETCH_GRADE_SELECTOR_LCV_FILTERS,
    modelId,
});

export const fetchGradeSelectorFinance = (
    modelId: string,
    waitForUncachedResults: boolean,
): FetchGradeSelectorFinanceType => ({
    fetchType: FETCH_GRADE_SELECTOR_FINANCE,
    modelId,
    waitForUncachedResults,
});

export type GradeSelectorFetchTypes =
    | FetchGradeSelectorSettingsType
    | FetchGradeSelectorModelType
    | FetchGradeSelectorLcvFilterType
    | FetchGradeSelectorFinanceType;

export const getGradeSelectorComponentFetches =
    (modelId?: string) =>
    (state: GradeSelectorStateType): GradeSelectorFetchTypes[] => {
        const isSSR = !DOMUtils.canUseDOM;

        const fetches: GradeSelectorFetchTypes[] = [];
        const {
            gradeSelectorSettings,
            gradeSelectorModel,
            gradeSelectorFinance,
            gradeSelectorLcvFilters,
            commonSettings,
        } = state;

        if (!gradeSelectorSettings.initialized) {
            // Integrated grade switcher uses modelId which is passed as a prop from the state.
            // Standalone grade switcher doesn't have this data yet, use modelId from query instead.
            let modelMapModelId: string | undefined;

            if (!modelId && commonSettings.query.modelId) {
                const modelMapModel = searchModelMapValue<CommonSettingsType>(
                    commonSettings,
                    commonSettings.query.modelId,
                );
                modelMapModelId = modelMapModel?.id;
            }

            fetches.push(fetchGradeSelectorSettings(modelId ?? modelMapModelId));
        }

        if (!gradeSelectorModel.initialized && gradeSelectorSettings.initialized) {
            fetches.push(fetchGradeSelectorModel(gradeSelectorSettings, modelId));
        }

        if (
            gradeSelectorModel.initialized &&
            gradeSelectorSettings.initialized &&
            gradeSelectorFinance.state === RequestStatusEnum.Idle &&
            showAemMonthlyRates(commonSettings, AemFmComponent.GradeSelector, "new", gradeSelectorModel.model.code)
        ) {
            fetches.push(fetchGradeSelectorFinance(gradeSelectorModel.model.id, false));
        }

        if (gradeSelectorFinance.state === RequestStatusEnum.Fetching && !isSSR) {
            fetches.push(fetchGradeSelectorFinance(gradeSelectorModel.model.id, true));
        }

        if (
            gradeSelectorModel.initialized &&
            modelIsLcv(gradeSelectorModel.model.id) &&
            !gradeSelectorLcvFilters.initialized
        ) {
            fetches.push(fetchGradeSelectorLcvFilters(gradeSelectorModel.model.id));
        }

        return fetches;
    };

// ----------------------------------------------------------------------
// Dispatcher
// ----------------------------------------------------------------------
export const gradeSelectorFetchDispatcher = (
    data: Required<GradeSelectorFetchTypes>,
    dispatch: GradeSelectorDispatchType,
): void => {
    switch (data.fetchType) {
        case FETCH_GRADE_SELECTOR_SETTINGS:
            if (data.result.fetchResult) {
                dispatch(initGradeSelectorSettings(data.result.fetchResult));
            }
            break;
        case FETCH_GRADE_SELECTOR_MODEL:
            if (data.result.fetchResult) {
                dispatch(initGradeSelectorModel(data.result.fetchResult));
            }
            break;
        case FETCH_GRADE_SELECTOR_LCV_FILTERS:
            dispatch(initGradeSelectorLcvFilters(data.result.fetchResult));
            break;
        case FETCH_GRADE_SELECTOR_FINANCE:
            // TODO: Error handling OR-11590
            if (data.result.fetchResult) {
                dispatch(initFinance(data.result.fetchResult));
            } else {
                dispatch(setFinanceState(RequestStatusEnum.Fetching));
            }
            break;
    }
};
