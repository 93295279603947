import {
    getLabelFn,
    GetLabelType,
    getOptionalLabelFn,
    GetOptionalLabelType,
    getUseLabel,
    getUseOptionalLabel,
    UseLabelType,
    UseOptionalLabelType,
} from "../../../../common-deprecated/hooks/useLabel";
import { GradeSelectorStateType } from "../redux/store";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { GradeSelectorSettingsType } from "../redux/reducers/GradeSelectorSettingsReducer";
import {
    getLabelComponent,
    getMemoLabelComponent,
    LabelComponentType,
} from "../../../../common-deprecated/components/label/Label";
import { T1Labels } from "../../../../common-deprecated/utils/t1labels";

export const gradeSelectorLabels = {
    ...T1Labels,

    // Grade selector
    gradeSelectorMainTitle: "Which <strong>{model}</strong> will you choose?",
    gradeSelectorMainSubtitle: "See the prices and costs",
    gradeSelectorGradeCtaButton: "Explore & configure",
    gradeSelectorTabsHighlights: "Highlights",
    gradeSelectorAvailableColours: "Available colours",
    gradeSelectorCompare: "Compare",
    // Only plural as we don't expect a delivery of car in a week
    gradeSelectorEta: "Delivery in {num} weeks",

    // Engine specs
    gradeSelectorMultipleEngineOptions: "Multiple engine options",
    gradeSelectorEngineSpecCTA: "Show all engine options",
    gradeSelectorAllEngineOptions: "All engine options",
    gradeSelectorCloseEngineOptions: "Close engine options",
    gradeSelectorSpecsUnavailable: "Not available",
    gradeSelectorSpecsOptional: "Optional",
    gradeSelectorSpecsIncluded: "Included",

    gradeSelectorSpecWheeldrive: "Wheel drive",
    gradeSelectorSpecTransmission: "Transmission",
    gradeSelectorSpecEnginePrice: "Engine price",
    gradeSelectorSpecEcoTagTitle: "CO2 Class",
    gradeSelectorSpecEcoBadgeTitle: "Etiquette",

    // Grade switcher
    gradeSelectorKeepGrade: "Keep",
    gradeSelectorSelectGrade: "Select",
    gradeSelectorGradeSwitcherTitle: "All {model} grades",

    // Fueltypes
    gradeSelectorFuelTypeStandard: "Standard",
    gradeSelectorFuelTypeAll: "All",

    // SR only a11y specific
    ariaToggleFuelInfo: "Toggle fuel info",
    ariaToggleCo2Info: "Toggle co2 info",
    ariaScrollPrevious: "Scroll Previous",
    ariaScrollNext: "Scroll Next",
    ariaFilterNavigation: "Filters",

    // sliders
    ariaMoveSliderStart: "Move slider to start",
    ariaMoveSliderEnd: "Move slider to end",

    // Grade compare
    gradeCompareTitle: "Compare up to {num} grades",
    gradeCompareSelectGrade: "Select grade",

    // Exchange rate
    gradeSelectorExchangeRate: "Fixed conversion rate: {primaryPrice} = {secondaryPrice}",

    // Filters
    gradeSelectorFilterAllGradesButton: "All grades",
    gradeSelectorFilterShowMore: "More filters",
    gradeSelectorFilterShowLess: "Fewer filters",
    gradeSelectorFilterSeeResultsDisabled: "See results",
    gradeSelectorFilterSeeResults: "See {amount} results",
    gradeSelectorFilterSeeResult: "See 1 result",
    gradeSelectorFilterEngineButton: "Engine",
    gradeSelectorFilterWheeldriveButton: "Wheel drive",
    gradeSelectorFilterTransmissionButton: "Transmission",
    gradeSelectorFilterColourButton: "Colour",
    gradeSelectorFilterLcvButton: "Van features",
    gradeSelectorAllFiltersButton: "All filters",
    gradeSelectorActiveFilters: "{amount} active filters",
    gradeSelectorActiveFilter: "1 active filter",
    gradeSelectorRemoveFilters: "Remove all",
    gradeSelectorRemoveLcvFilter: "Clear {filter} selection",

    gradeSelectorEngineOptions: "Engine options",
    gradeSelectorEngineInfo: "Engine info",
    gradeSelectorExteriorFeatures: "Exterior features",
    gradeSelectorInteriorFeatures: "Interior features",

    // Legend
    gradeSelectorLegendTitle: "Legend",
    gradeSelectorLegendStandard: "Standard",
    gradeSelectorLegendOptional: "Optional",
    gradeSelectorLegendNotApplicable: "Not applicable",

    // Retailer specific Toyota
    gradeSelectorRetailerChooseYour: "Choose your {model} grade",
    gradeSelectorRetailerGradeHighlightsCtaButton: "All highlights",
    gradeSelectorRetailerGradeSpecsCtaButton: "All specifications",
    gradeSelectorRetailerContinueCtaButton: "Continue configuration",

    // Retailer specific Lexus
    gradeSelectorRetailerBackToModelSelection: "Back to model selection",
};

export type GradeSelectorLabelType = keyof typeof gradeSelectorLabels;
type GradeSelectorLabelParamType = Partial<
    Record<"model" | "num" | "primaryPrice" | "secondaryPrice" | "amount" | "filter", string>
>;

type GradeSelectorLabelStateType = {
    commonSettings: CommonSettingsType;
    gradeSelectorSettings: GradeSelectorSettingsType;
};

export const useGradeSelectorLabel = getUseLabel(
    (state: GradeSelectorStateType) => state.gradeSelectorSettings.labels,
    (state: GradeSelectorStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseLabelType<GradeSelectorLabelType, GradeSelectorLabelParamType>;

export const useGradeSelectorOptionalLabel = getUseOptionalLabel(
    (state: GradeSelectorStateType) => state.gradeSelectorSettings.labels,
    (state: GradeSelectorStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as UseOptionalLabelType<GradeSelectorLabelType, GradeSelectorLabelParamType>;

export const getGradeSelectorLabel = getLabelFn(
    (state: GradeSelectorLabelStateType) => state.gradeSelectorSettings.labels,
    (state: GradeSelectorLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetLabelType<GradeSelectorLabelStateType, GradeSelectorLabelType, GradeSelectorLabelParamType>;

export const getGradeSelectorOptionalLabel = getOptionalLabelFn(
    (state: GradeSelectorLabelStateType) => state.gradeSelectorSettings.labels,
    (state: GradeSelectorLabelStateType) => !!state.commonSettings.query.showDictionaryKeys,
) as GetOptionalLabelType<GradeSelectorLabelStateType, GradeSelectorLabelType, GradeSelectorLabelParamType>;

export const GradeSelectorLabel = getLabelComponent(useGradeSelectorLabel) as LabelComponentType<
    GradeSelectorLabelType,
    GradeSelectorLabelParamType
>;

export const GradeSelectorMemoLabel = getMemoLabelComponent(useGradeSelectorLabel) as LabelComponentType<
    GradeSelectorLabelType,
    GradeSelectorLabelParamType
>;
