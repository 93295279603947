import React, { FC } from "react";
import clsx from "clsx";
import styles from "./FilterButton.module.scss";
import { GradeSelectorFilterId } from "../../../../../utils/filterConstants";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import { GradeSelectorFiltersType } from "../../../../../redux/slices/GradeSelectorFiltersSlice";
import Popover from "../../../../../../shared-components/css-modules/toyota/vendor/popover/Popover";
import Button from "../../../../../../shared-components/css-modules/toyota/atoms/Button";
import { GradeSelectorLabel } from "../../../../../utils/labels";
import Text from "../../../../../../shared-components/css-modules/toyota/atoms/Text";
import Radio from "../../../../../../shared-components/css-modules/toyota/molecules/Radio";
import { FilterDataType } from "../../../../../utils/filterUtils";
import Checkbox from "../../../../../../shared-components/css-modules/toyota/molecules/Checkbox";
import { IconVariantTypes } from "../../../../../../shared-components/css-modules/toyota/atoms/Icon.types";
import useFilterButton from "../../../../../hooks/useFilterButton";

type FilterButtonType = {
    filterId: GradeSelectorFilterId;
    activeFilters: GradeSelectorFiltersType | undefined;
    filterData: FilterDataType[];
    hasActiveFilter: boolean;
};

const FilterButton: FC<FilterButtonType> = (props) => {
    const { filterId, activeFilters, filterData, hasActiveFilter } = props;

    const filterButton = useFilterButton(filterId, activeFilters, filterData);
    const { addFilter, clearFilters, handleColourInput, onOpenChange, handleOpen, setSelectedFilters } = filterButton;
    const { buttonLabel, buttonText, closeLabel, resultButtonLabel } = filterButton;
    const { filterResultAmount, isButtonDisabled, isOpen, selectedFilters } = filterButton;

    const icon: IconVariantTypes = isOpen ? "chevron-up" : hasActiveFilter ? "close" : "chevron-down";

    return (
        <Popover
            ancestorScroll
            onOpenChange={handleOpen}
            open={isOpen}
            onOpenChangeListen={onOpenChange}
            placement="bottom-start"
        >
            <Popover.Trigger onClick={handleOpen}>
                <Button
                    className={clsx(
                        styles["filter-button-wrapper"],
                        hasActiveFilter && !isOpen && styles.active,
                        isOpen && styles.isOpen,
                    )}
                    variant="clean"
                >
                    {/* Only show filter value for radio filters. */}
                    {!isOpen && hasActiveFilter && filterId !== GradeSelectorFilterId.Colour ? (
                        <Text>{buttonText}</Text>
                    ) : (
                        <GradeSelectorLabel label={buttonLabel} />
                    )}
                    <Icon
                        onClick={(event) =>
                            hasActiveFilter &&
                            !isOpen &&
                            (event.stopPropagation(), event.preventDefault(), clearFilters())
                        }
                        variant={icon}
                        sizing="sm"
                        aria-hidden="true"
                    />
                </Button>
            </Popover.Trigger>
            <Popover.Content variant="empty">
                <div className={styles["filter-button-content-wrapper"]}>
                    <header className={styles["filter-button-header"]}>
                        <Popover.Close>
                            <Button aria-label="close" variant="clean">
                                <Icon variant="close" sizing="md" aria-hidden="true" />
                                <Text>{closeLabel}</Text>
                            </Button>
                        </Popover.Close>
                        <Button onClick={addFilter} disabled={isButtonDisabled} variant="btn-default">
                            <GradeSelectorLabel
                                label={resultButtonLabel}
                                params={{
                                    amount: filterResultAmount.toString(),
                                }}
                            />
                        </Button>
                    </header>
                    <div className={styles["filter-button-content"]}>
                        {filterData.map((value, index) =>
                            // Colour filters (filters with assets) are multiple choice (checkboxes).
                            filterId === GradeSelectorFilterId.Colour ? (
                                <Checkbox
                                    key={value.code}
                                    label={value.name}
                                    disabled={value.uniqueGradeBodyTypeIds.length === 0}
                                    swatch={value.swatch}
                                    className={styles.swatch}
                                    onChange={() => handleColourInput(value.code)}
                                    checked={selectedFilters.includes(value.code)}
                                />
                            ) : (
                                <Radio
                                    key={value.code}
                                    label={value.name}
                                    disabled={value.uniqueGradeBodyTypeIds.length === 0}
                                    onChange={() => setSelectedFilters([index.toString()])}
                                    checked={!!selectedFilters.length && Number(selectedFilters) === index}
                                    amount={value.uniqueGradeBodyTypeIds.length}
                                />
                            ),
                        )}
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );
};

export default FilterButton;
