import {
    CarConfigStep,
    getCarConfigUrl,
} from "../../../../common-deprecated/features/apheleia/utils/carConfigNavigationUtils";
import { getCarImage } from "../../shared-logic/utils/imageUtils";
import { useGradeSelectorSelector } from "../redux/hooks";
import { selectModel } from "../redux/slices/GradeSelectorModelSlice";
import { GradeSelectorStateType } from "../redux/store";
import { BodyTypeType, CarType, EquipmentType, GradeType } from "./types/Model";

export const useVisibleEquipment = (path?: string): EquipmentType[] => {
    const { equipment } = useGradeSelectorSelector(selectModel);
    return equipment.filter(({ groupPath }) => (path ? path === groupPath : true));
};

/**
 * Get image for current grade and bodyType
 *
 * @param grade
 * @param bodyType
 * @param width
 * @param height
 * @param angle
 * @returns
 */
export const useGradeImage = (
    grade: GradeType,
    bodyType: BodyTypeType,
    car: CarType,
    width: number,
    height: number,
    angle: number,
    type: "jpg" | "png" = "jpg",
    colourCodes?: string,
): ReturnType<typeof getCarImage> => {
    const commonSettings = useGradeSelectorSelector((state: GradeSelectorStateType) => state.commonSettings);
    const { id } = useGradeSelectorSelector(selectModel);
    return getCarImage(
        commonSettings,
        angle,
        {
            modelId: id,
            gradeId: grade.id,
            bodyTypeId: bodyType.id,
            carId: car.CarID,
        },
        {
            type,
            width,
            height,
            bgColour: type === "png" ? "" : "FFFFFF",
        },
        {
            colorCode: colourCodes,
        },
    );
};

export const useCarConfigUrl = (
    car: CarType,
    carConfigStep?: CarConfigStep,
    colour?: { colourId: string; colourCode: string },
): string => {
    const commonSettings = useGradeSelectorSelector((state: GradeSelectorStateType) => state.commonSettings);
    const model = useGradeSelectorSelector(selectModel);

    return getCarConfigUrl(
        commonSettings,
        model.id,
        model.code,
        car.CarID,
        carConfigStep || CarConfigStep.Configure,
        colour,
    );
};
