import { CommonSettingsType } from "../settings/fetchCommonSettings";
import { getImageWithFallback } from "./assetUtils";
import { VERSION_6HD } from "../../new-cars/original/buildandbuy/utils/constants";
import { isLexus } from "../settings/utils/commonSettingUtils";
import { CarType } from "../../new-cars/shared-logic/types/CarType";
import { PersonalizationType } from "../types/CommonTypes";

export const getCarDBAssetUrl = <T extends string | undefined>(
    asset: T,
    compressedCcisPath: string,
    width: number,
    height: number,
): T => {
    const split = asset?.match(/\/([^/]+)\.(png|jpg)$/i); // Splits the current cardb asset URL to get the id of the asset
    if (split && split[1] && split[2]) {
        // uses the current filetype and resizes to the given width and converts to webp
        return `${compressedCcisPath}/cardb/${split[2]}/resize/${width}X${height}/${split[1]}.webp` as T;
    } else {
        return asset;
    }
};

/**
 * Service created by Peter Vandercappellen to easily resize images/get different filetypes
 * Is done using imageMagick, resize syntax should be the same
 * Only used for CarDB assets!
 * @param srcSetArray - array with all srcSets needed. Will take smallest value in aspect ratio. FE: 350x1000 on an image of 350x350 will give back 350x350 becausxe of the aspect ratio
 * @param asset - Asset URL that needs to be optimized Example: https://t1-carassets.toyota-europe.com/5f056fae-a3a5-4d0b-a37c-15303a370be6.JPG
 */
export const getCarDBAssetSrcSet = (
    settings: CommonSettingsType,
    srcSetArray: { width: number; height: number }[],
    asset: string,
): { src: string; srcSet?: string } => {
    if (!asset) return { src: getImageWithFallback(settings.resourcePath, asset, settings.brand) };
    // If the compressedCcisPath setting is not set these improvements cannot be done. Return the original asset
    if (!settings.compressedCcisPath) return { src: asset };

    const srcSet = srcSetArray
        .map((srcSetValue) => {
            const url = getCarDBAssetUrl(asset, settings.compressedCcisPath, srcSetValue.width, srcSetValue.height);
            return `${url ?? ""} ${srcSetValue.width}w`;
        })
        .join(",");
    return { src: asset, srcSet };
};

// This setting is overriden by a check if webgl is supported inside useWebGLSupported
export const show3dSpin = (settings: CommonSettingsType, configVersion: string): boolean => {
    if (isLexus(settings)) return false;
    if (settings.query.carConfigVersion) return settings.query.carConfigVersion === VERSION_6HD; // override with queryparam
    return configVersion === VERSION_6HD;
};
export const getInteriorSpinFlags = (
    commonSettings: CommonSettingsType,
    car: CarType,
    activePersonalization: PersonalizationType,
    webGLSupportLevel: number,
): { enabled: boolean; interior3D: boolean; interior2D: boolean } => {
    const carConfigVersion = commonSettings.query.carConfigVersion || car.model.configVersion;
    const has3DAssets = !isLexus(commonSettings) && carConfigVersion === VERSION_6HD;

    const enable3dSpin = has3DAssets && webGLSupportLevel > 1;

    const isUpholstery = activePersonalization === "upholstery";
    const showInterior3D = isUpholstery && enable3dSpin && webGLSupportLevel === 2;
    const showInterior2D = isUpholstery && !showInterior3D && !has3DAssets;
    const showInterior = showInterior2D || showInterior3D;

    return {
        enabled: showInterior,
        interior3D: showInterior3D,
        interior2D: showInterior2D,
    };
};
