import React, { FC, useCallback, useRef, memo } from "react";
import clsx from "clsx";
import Item from "../item/Item";

import { getUniqueGradeBodyTypeId } from "../../../../../../../common-deprecated/features/apheleia/utils/aphUtils";
import styles from "./Slider.module.scss";
import Container from "../../../../../shared-components/css-modules/toyota/atoms/Container";
import { trackBrowseGrades } from "../../../../utils/tracking";
import { useSlider } from "../../../../../../../common-deprecated/features/apheleia/hooks/useSlider";
import { useFilteredGradeBodyTypes } from "../../../../utils/filterHooks";
import Filter from "../actions/filter/Filter";
import SliderButtons from "./SliderButtons";
import ActiveFilters from "../actions/filter/ActiveFilters";
import Legend from "./legend/Legend";
import { useGradeSelectorSelector } from "../../../../redux/hooks";
import { LcvFilters } from "../../lcv-filter/LcvFilters";
import { MobileLcvFilterControls } from "../../lcv-filter/MobileLcvFilterControls";

const Slider: FC = () => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const anyItemRef = useRef<HTMLDivElement | null>(null);
    const hasLcvFilters = useGradeSelectorSelector(
        (state) => state.gradeSelectorLcvFilters.initialized && state.gradeSelectorLcvFilters.filters.length > 0,
    );
    const gradeBodyTypes = useFilteredGradeBodyTypes();

    const trackBrowse = useCallback(() => {
        trackBrowseGrades();
    }, []);

    const { navigate, prevButtonDisabled, nextButtonDisabled, elementProps, isDragging } = useSlider(
        wrapperRef,
        anyItemRef,
        trackBrowse,
        trackBrowse,
    );

    return (
        <>
            <Container>
                <div className={styles.navigation}>
                    {!hasLcvFilters ? <Filter /> : <LcvFilters />}
                    <SliderButtons
                        amount={gradeBodyTypes.length}
                        navigate={navigate}
                        nextButtonDisabled={nextButtonDisabled}
                        prevButtonDisabled={prevButtonDisabled}
                    />
                </div>
                {hasLcvFilters && <MobileLcvFilterControls />}
            </Container>
            <ActiveFilters />
            <div
                id="gs-slider"
                className={clsx(styles.panel, isDragging && styles.dragging)}
                ref={wrapperRef}
                {...elementProps}
            >
                {gradeBodyTypes.map((gradeBodyType, index) => (
                    <Item
                        key={getUniqueGradeBodyTypeId(
                            gradeBodyType.grade.id,
                            gradeBodyType.bodyType.id,
                            gradeBodyType.submodel?.id,
                        )}
                        ref={index === 0 ? anyItemRef : undefined}
                        gradeBodyType={gradeBodyType}
                    />
                ))}
            </div>
            <div className={styles["mobile-slider-buttons"]}>
                <SliderButtons
                    amount={gradeBodyTypes.length}
                    navigate={navigate}
                    nextButtonDisabled={nextButtonDisabled}
                    prevButtonDisabled={prevButtonDisabled}
                />
            </div>

            <Legend />
        </>
    );
};

export default memo(Slider);
