import { EcoTagType } from "../../../../common-deprecated/types/CommonTypes";
import { NewCarFuelTypeFilterId } from "../../../../common-deprecated/constants";

export const getEcoTagType = <
    T extends { plugin?: boolean; fuel: { fuelCell?: boolean; hybrid?: boolean; electric?: boolean; code: string } },
>(
    engine: T,
): EcoTagType | undefined => {
    if (engine.fuel.fuelCell === true) {
        return EcoTagType.FuelCell;
    }

    if (engine.plugin === true) {
        return EcoTagType.Plugin;
    }

    if (engine.fuel.hybrid === true) {
        return EcoTagType.Hybrid;
    }

    if (engine.fuel.electric === true) {
        return EcoTagType.Electric;
    }

    if (engine.fuel.code === NewCarFuelTypeFilterId.Diesel) {
        return EcoTagType.Diesel;
    }

    if (engine.fuel.code === NewCarFuelTypeFilterId.Petrol) {
        return EcoTagType.Petrol;
    }

    return undefined;
};
