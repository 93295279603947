import React, { memo } from "react";
import groupBy from "lodash/groupBy";
import clsx from "clsx";
import styles from "./InfoSection.module.scss";
import { GradeBodyTypeType } from "../../../../../utils/types/Model";
import Text from "../../../../../../shared-components/css-modules/toyota/atoms/Text";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import Specs from "../specs/EngineInfo";
import { GradeSelectorLabel } from "../../../../../utils/labels";
import {
    useGradeSelectorDispatch,
    useGradeSelectorFilterCategory,
    useGradeSelectorSelector,
} from "../../../../../redux/hooks";
import { getCategoryGroupIcon, getMotorizations } from "../../../../../utils/gradeUtils";
import Collapse from "../../../../../../shared-components/css-modules/toyota/molecules/Collapse";
import { setFilterCategory } from "../../../../../redux/slices/GradeSelectorFiltersSlice";
import EquipmentView from "../EquipmentView";

type InfoSectionType = {
    gradeBodyType: GradeBodyTypeType;
};

const InfoSection = ({ gradeBodyType }: InfoSectionType): JSX.Element => {
    const dispatch = useGradeSelectorDispatch();
    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorFilters);
    const currentFilter = useGradeSelectorFilterCategory();
    const equipmentGroups = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.equipmentGroups);

    const hasMultipleMotorizations = getMotorizations(gradeBodyType, activeFilters).length > 1;

    const categorizedItems = groupBy(
        equipmentGroups.filter(({ hasKeyFeatures }) => hasKeyFeatures),
        "root",
    );

    return (
        <div className={styles["info-section-wrapper"]}>
            <div className={clsx(styles["category-group"], styles["first-category-group"])}>
                <header className={styles["category-header"]}>
                    <Icon sizing="md" variant="engine" aria-hidden="true" />
                    <GradeSelectorLabel
                        label={hasMultipleMotorizations ? "gradeSelectorEngineOptions" : "gradeSelectorEngineInfo"}
                    />
                </header>
                <div className={styles["features-list-category"]}>
                    <Specs gradeBodyType={gradeBodyType} />
                </div>
            </div>

            {Object.entries(categorizedItems).map(([category, items], index) => {
                const isCategoryOpen = currentFilter?.root ? currentFilter.root === category : index === 0;
                const categoryGroupIcon = getCategoryGroupIcon(equipmentGroups, category);

                return (
                    <Collapse
                        className={styles["category-group"]}
                        key={category}
                        disabled={isCategoryOpen}
                        open={isCategoryOpen}
                        onChange={() => dispatch(setFilterCategory({ category }))}
                        summary={
                            <header className={styles["category-header"]}>
                                {categoryGroupIcon && (
                                    <Icon sizing="sm" variant={categoryGroupIcon} aria-hidden="true" />
                                )}
                                <Text>{category}</Text>
                            </header>
                        }
                    >
                        {items.map((equipmentGroup) => (
                            <div className={styles["features-list-item"]} key={equipmentGroup.path}>
                                <Collapse
                                    key={equipmentGroup.path}
                                    summary={equipmentGroup.category}
                                    open={currentFilter?.subCategory === equipmentGroup.category}
                                    onChange={() => {
                                        dispatch(
                                            setFilterCategory({ category: equipmentGroup.path, allowClose: true }),
                                        );
                                    }}
                                >
                                    <EquipmentView gradeBodyType={gradeBodyType} forceFilter={equipmentGroup.path} />
                                </Collapse>
                            </div>
                        ))}
                    </Collapse>
                );
            })}
        </div>
    );
};

export default memo(InfoSection);
