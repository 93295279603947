import { CarDBModelId } from "../../../common-deprecated/constants";

export const modelIsProAce = (modelId: string): boolean => {
    return modelId === CarDBModelId.Proace;
};

export const modelIsLcv = (modelId: string): boolean => {
    return (
        modelIsProAce(modelId) ||
        modelId === CarDBModelId.ProaceVerso ||
        modelId === CarDBModelId.ProaceCity ||
        modelId === CarDBModelId.ProaceCityVerso ||
        modelId === CarDBModelId.ProaceMax
    );
};
