import { AccentColourType, PackType } from "../../../../common-deprecated/types/CommonTypes";
import { getDefaultPriceObject } from "../../../../common-deprecated/constants";

export const defaultPackObject: PackType = {
    index: 0,
    id: "",
    shortId: "",
    objectType: "pack",
    name: "",
    standard: false,
    extended: null,
    availability: {
        colours: [],
        upholsteries: [],
    },
    visualItems: {
        standard: [],
        optional: [],
        wheels: [],
        colours: [],
        upholsteries: [],
        equipmentUpholsteries: [],
    },
    description: "",
    equipment: [],
    serviceProducts: [],
    image: "",
    footNote: null,
    equipmentDescription: "",
    included: [],
    excluded: [],
    includePacks: [],
    excludePacks: [],
    price: { ...getDefaultPriceObject() },
    userSelected: false,
    subtitle: "",
    standardOnMonthlyRateToggle: false,
    sortIndex: 0,
};

export const defaultAccentColourObject: AccentColourType = {
    id: "",
    code: "",
    name: "",
    rgb: "",
    default: false,
    image: "",
    accentColours: [],
};

// Local and Session storage keys
export const SESSION_STORAGE_FINANCE_QUOTES_KEY = "com.toyota.tme.quotes";

export const SESSION_STORAGE_BUSINESS_FINANCE_QUOTES_KEY = "com.toyota.tme.business.quotes";

// CarDB Carconfig versions (used for toggling between interior views: gallery/spin)
export const VERSION_6HD = "6.HD";
export const VERSION_6HD_INT_GALLERY = "6.HD+INTERIOR GALLERY";

export const ALLOWEDCASHPRODUCT = "cash";
export const ALLOWEDMONTHLYPRODUCT = "monthly";
