import { formatPrice } from "../../../Globalize";
import { FormatMonthlyPriceType, formatMonthlyPrice, MonthlyFinanceInfoLabelKey } from "../../../priceUtils";
import { CommonSettingsType } from "../../../settings/fetchCommonSettings";
import { ApiPriceType } from "../../../types/ApiModel";
import { FinanceInfoType, PriceType } from "../../../types/CommonTypes";
import { FinanceCarItemType, FinanceItemQuoteType, FinanceResponseType } from "./types/Finance";

/**
 * Formats financing monthly prices. Can be formatted as 1 string instead of FormatMonthlyPriceType
 */
export const formatMonthlyFinancePrice = <T extends FormatMonthlyPriceType | string>(
    price: PriceType,
    financeInfo: FinanceInfoType,
    commonSettings: CommonSettingsType,
    priceFormat: string,
    rateWithoutPromotions?: number,
    formatAsString?: boolean,
): T => {
    if (price.monthly === 0) return "" as T;
    const term = financeInfo.term || financeInfo.duration || "";
    const tan = financeInfo.annualInterestRate || financeInfo.interestRate || "";
    const financeInfoReplacements: Record<MonthlyFinanceInfoLabelKey, string> = {
        term,
        productName: financeInfo.productName || "",
        taeg: financeInfo.effectiveInterestRate || "",
        tan,
        // residualValue and downPayment are price values, so apply formatPrice as well.
        residualValue: financeInfo.residualValue
            ? formatPrice(financeInfo.residualValue, commonSettings.culture.name, true)
            : "",
        downPayment: financeInfo.downPayment
            ? formatPrice(financeInfo.downPayment, commonSettings.culture.name, true)
            : "",
        totalAmountFinanced: financeInfo.totalAmountFinanced
            ? formatPrice(financeInfo.totalAmountFinanced, commonSettings.culture.name, true)
            : "",
        amountFinanced: financeInfo.amountFinanced
            ? formatPrice(financeInfo.amountFinanced, commonSettings.culture.name, true)
            : "",
        totalCreditWithFees: financeInfo.totalCreditCostWithFee
            ? formatPrice(financeInfo.totalCreditCostWithFee, commonSettings.culture.name, true)
            : "",
        mileage: financeInfo.mileage || "",
        lastInstalment: financeInfo.lastInstalment || "",
        disclaimer: "", // This is supported for USC components but not for NC for now.
        // Used in USC but not NC
        margin: "",
        euriborDate: "",
        euriborValue: "",
        euriborMonths: "",
        installments: financeInfo.installments || "",
    };

    const monthlyRates = formatMonthlyPrice(
        price,
        financeInfo,
        commonSettings,
        priceFormat,
        financeInfoReplacements,
        rateWithoutPromotions,
    );

    return formatAsString
        ? (monthlyRates.labels
              .map((label) => {
                  return label.value;
              })
              .join(" ") as T)
        : (monthlyRates as T);
};

export const formatFinance = (data: FinanceResponseType): Record<string, FinanceCarItemType> => {
    const formattedFinanceData: Record<string, FinanceCarItemType> = {};
    const formatTable = (
        calculations: FinanceItemQuoteType["calculations"] = [],
    ): { name: string; value: string }[] => {
        return calculations.map(({ name, value, format }) => ({
            name,
            value,
            colour: format.colour,
            bold: format.b,
            italic: false,
            underline: false,
        }));
    };
    Object.entries(data).forEach(([id, finance]) => {
        if (finance.rate && finance.rate.quote) {
            const disclaimer = {
                value: finance.rate.quote.value || "",
                table: finance.rate.quote.calculations ? formatTable(finance.rate.quote.calculations) : [],
            };
            formattedFinanceData[id] = {
                financeInfo: {
                    annualInterestRate: finance.rate.annualInterestRate
                        ? finance.rate.annualInterestRate.formatted
                        : "",
                    effectiveInterestRate: finance.rate.effectiveInterestRate
                        ? finance.rate.effectiveInterestRate.formatted
                        : "",
                    disclaimer,
                    term: finance.rate.term?.value || finance.rate.duration?.value || "",
                    residualValue: finance.rate.residualValue?.value,
                    downPayment: finance.rate.downpayment?.value,
                    mileage: finance.rate.mileage?.value,
                    duration: finance.rate.duration?.value,
                    interestRate: finance.rate.interestRate?.value,
                    monthlyPayment: finance.rate.monthlyPayment?.value,
                },
                quote: finance.rate.quote,
            };
        }
    });

    return formattedFinanceData;
};

export const mapPrice = (price: ApiPriceType, finance: FinanceCarItemType["financeInfo"]): PriceType => ({
    cash: price.listWithDiscount ?? 0,
    monthly: parseFloat(finance.monthlyPayment ?? "0"),
    monthlyWithoutPromotion: parseFloat(finance.monthlyPayment ?? "0"),
    discount: price.listDiscount ?? 0,
    promotions: [],
    highlight: false,
    exclVat: price.netWithDiscount ?? 0,
    licenseFee: 0,
    onlineCashDiscount: 0,
    onlineMonthlyDiscount: 0,
    onlineCashPromotions: [],
    onlineMonthlyPromotions: [],
});

export const appendMonthlyPrice = (price: PriceType, finance: FinanceCarItemType["financeInfo"]): PriceType => ({
    ...price,
    monthly: parseFloat(finance.monthlyPayment ?? "0"),
    monthlyWithoutPromotion: parseFloat(finance.monthlyPayment ?? "0"),
});
