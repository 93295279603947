import React, { FC } from "react";
import { formatPriceIntl } from "../../../../../../../common-deprecated/priceUtils";
import Text from "../../../../../shared-components/css-modules/toyota/atoms/Text";
import { useGradeSelectorSelector } from "../../../../redux/hooks";
import { GradeSelectorMemoLabel } from "../../../../utils/labels";

const ExchangeRate: FC = () => {
    const commonSettings = useGradeSelectorSelector((state) => state.commonSettings);

    if (!commonSettings.currencyMultiplier) {
        return null;
    }

    const { primaryPrice, secondaryPrice } = formatPriceIntl(
        commonSettings,
        1,
        { currencyDisplay: "code" },
        true,
        undefined,
        // as secondary value is not really a price that needs to take in
        // account maximumFractionDigits from commonSettings, we set it to max
        20,
    );

    return (
        <Text variant="apheleia-body" color="grey-7">
            <GradeSelectorMemoLabel label="gradeSelectorExchangeRate" params={{ primaryPrice, secondaryPrice }} />
        </Text>
    );
};

export default ExchangeRate;
