import { createLogic } from "redux-logic";
import { GradeSelectorLogicType } from "../store";
import {
    initGradeSelectorLcvFilters,
    resetGradeSelectorLcvFilters,
    setGradeSelectorLcvFilterEnabledValues,
    setGradeSelectorLcvFilterValue,
} from "../slices/GradeSelectorLcvFiltersSlice";
import { filterLcvFilterCars, getFilteredFilterValues, setEnabledFilterValues } from "../../utils/filterUtils";

const resetGradeSelectorLcvFiltersLogic = (
    createLogic as GradeSelectorLogicType<ReturnType<typeof resetGradeSelectorLcvFilters>>
)({
    type: resetGradeSelectorLcvFilters.type,
    process({ getState }, dispatch, done) {
        const { cars } = getState().gradeSelectorLcvFilters;
        dispatch(setGradeSelectorLcvFilterEnabledValues(setEnabledFilterValues(cars, [], "")));
        done();
    },
});

const initGradeSelectorLcvFiltersLogic = (
    createLogic as GradeSelectorLogicType<ReturnType<typeof initGradeSelectorLcvFilters>>
)({
    type: initGradeSelectorLcvFilters.type,
    process({ action }, dispatch, done) {
        const cars = action.payload?.cars;
        if (cars) {
            dispatch(setGradeSelectorLcvFilterEnabledValues(setEnabledFilterValues(cars, [], "")));
        }
        done();
    },
});

const setGradeSelectorLcvFilterValueLogic = (
    createLogic as GradeSelectorLogicType<ReturnType<typeof setGradeSelectorLcvFilterValue>>
)({
    type: setGradeSelectorLcvFilterValue.type,
    validate({ getState, action }, allow, reject) {
        // Reject when no changes
        const { filterCode, value } = action.payload;
        const currentFilterValue = getState().gradeSelectorLcvFilters.activeFilters[filterCode];
        if (currentFilterValue === value) {
            // @ts-expect-error
            reject();
        } else {
            allow(action);
        }
    },
    process({ getState, action }, dispatch, done) {
        const { filterCode } = action.payload;
        const { cars, activeFilters, enabledFilterValues } = getState().gradeSelectorLcvFilters;

        const newFilters = getFilteredFilterValues(action.payload, activeFilters);
        const filterCars = filterLcvFilterCars(cars, newFilters);

        if (filterCars.length) {
            dispatch(
                setGradeSelectorLcvFilterEnabledValues(
                    setEnabledFilterValues(filterCars, enabledFilterValues[filterCode] || [], filterCode),
                ),
            );
        }

        done();
    },
});

export default [
    initGradeSelectorLcvFiltersLogic,
    setGradeSelectorLcvFilterValueLogic,
    resetGradeSelectorLcvFiltersLogic,
];
