import React, { PropsWithChildren, forwardRef, memo } from "react";
import clsx from "clsx";
import styles from "./Button.module.scss";

export type Props = {
    className?: string;
    type?: string;
    variant?:
        | "btn-default"
        | "btn-secondary"
        | "btn-text"
        | "btn-icon-text"
        | "btn-icon"
        | "btn-icon-dark"
        | "apheleia-text"
        | "clean"
        | "btn-arrow"
        | "swatch"
        | "btn-border-bottom"
        | "btn-toggle";
    sizing?: "xs" | "sm" | "lg" | "full-width" | "pill";
    circle?: boolean;
    skeleton?: boolean;
} & React.HTMLProps<HTMLAnchorElement | HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>((props, ref) => {
    const { type = "button", variant = "btn-default", sizing, circle, skeleton, className, children, ...rest } = props;

    return React.createElement(
        props.as || props.href ? "a" : "button",
        {
            ...rest,
            ref,
            type: rest.href ? undefined : type,
            className: clsx(
                styles[variant],
                sizing && styles[sizing],
                circle && styles.circle,
                skeleton && styles.skeleton,
                className,
            ),
        },
        children,
    );
});

Button.displayName = "Button";
export default memo(Button);
