import React, { FC, memo } from "react";
import styles from "./Filter.module.scss";
import { useGradeSelectorDispatch, useGradeSelectorSelector } from "../../../../../redux/hooks";
import { GradeSelectorLabel } from "../../../../../utils/labels";
import Button from "../../../../../../shared-components/css-modules/toyota/atoms/Button";
import Icon from "../../../../../../shared-components/css-modules/toyota/atoms/Icon";
import { clearFilters } from "../../../../../redux/slices/GradeSelectorFiltersSlice";
import { getActiveFilterAmount } from "../../../../../utils/filterUtils";

const ActiveFilters: FC = () => {
    const dispatch = useGradeSelectorDispatch();

    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorFilters);
    const activeFilterAmount = getActiveFilterAmount(activeFilters);

    if (activeFilterAmount === 0) return null;

    return (
        <div className={styles["active-filters-wrapper"]}>
            <div className={styles["active-filters-content"]}>
                {activeFilterAmount === 1 ? (
                    <GradeSelectorLabel label="gradeSelectorActiveFilter" />
                ) : (
                    <GradeSelectorLabel
                        label="gradeSelectorActiveFilters"
                        params={{ amount: activeFilterAmount.toString() }}
                    />
                )}
                <Button onClick={() => dispatch(clearFilters())} aria-label="close" variant="clean">
                    <GradeSelectorLabel label="gradeSelectorRemoveFilters" />
                    <Icon variant="close" sizing="md" aria-hidden="true" />
                </Button>
            </div>
        </div>
    );
};

export default memo(ActiveFilters);
