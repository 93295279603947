import { EcolabelType } from "../../../../common-deprecated/constants";
import { EcoTagLabelType, ecoTagToLabel } from "../../../../common-deprecated/utils/ecoTagUtils";
import { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import {
    getEcoBadgeType,
    hasAlternativeEcoSpecFlow,
} from "../../../../common-deprecated/settings/utils/commonSettingUtils";
import { EcoTagType } from "../../../../common-deprecated/types/CommonTypes";
import { EcoLabelPopupLabelType } from "../../../../common-deprecated/types/SettingsType";
import { getEcoBadgeUrl } from "../../../../common-deprecated/utils";
import { getEcoSpec, getSpec } from "./gradeUtils";
import { GradeBodyTypeEngineTransmissionWheeldriveType, GradeBodyTypeEngineType } from "./types/Model";
import { getEcoTagType } from "../../shared-logic/utils/ecoUtils";

export type EcoItem = {
    ecoTag?: EcoTagType;
    ecoTagLabel?: EcoTagLabelType;
    ecoBadge: false | EcolabelType;
    ecoBadgeUrl: string | null;
    efficiencyLabelConfig: LabelConfig;
};

export type DisableEcoItemType = { efficiencyLabel?: boolean; ecoBadge?: boolean; ecoTag?: boolean } | undefined;

export type LabelConfig = { show: true; label: EcoLabelPopupLabelType } | { show: false };

export const getLabelConfig = (wheeldrive: GradeBodyTypeEngineTransmissionWheeldriveType): LabelConfig => {
    const co2combinedwltp = getSpec(wheeldrive, "co2combinedwltp");
    const energyEfficiencyClass = getEcoSpec(wheeldrive);

    if (co2combinedwltp && energyEfficiencyClass) {
        return {
            show: true,
            label: {
                letter: energyEfficiencyClass.value,
                label: `${co2combinedwltp.value} ${co2combinedwltp.unit}`,
            },
        };
    }

    return {
        show: false,
    };
};

export const getEcoItem = (
    modelId: string,
    engine: GradeBodyTypeEngineType,
    commonSettings: CommonSettingsType,
    disable?: DisableEcoItemType,
): EcoItem => {
    const tag = getEcoTagType(engine);
    const badge = getEcoBadgeType(commonSettings, tag, modelId);
    const alternativeEcoSpecFlow = hasAlternativeEcoSpecFlow(commonSettings);

    const ecoItem: EcoItem = {
        ecoTag: !disable?.ecoTag ? tag : undefined,
        ecoTagLabel: !disable?.ecoTag ? ecoTagToLabel(tag) : undefined,
        ecoBadge: !disable?.ecoBadge ? badge : false,
        ecoBadgeUrl: !disable?.ecoBadge ? getEcoBadgeUrl(badge, commonSettings) : null,
        efficiencyLabelConfig:
            !disable?.efficiencyLabel && !alternativeEcoSpecFlow
                ? getLabelConfig(engine.transmissions[0].wheeldrives[0])
                : { show: false },
    };

    return ecoItem;
};

export const getEcoItems = (
    modelId: string,
    engines: GradeBodyTypeEngineType[],
    commonSettings: CommonSettingsType,
    disable?: DisableEcoItemType,
): EcoItem[] =>
    engines
        .map((engine): EcoItem => getEcoItem(modelId, engine, commonSettings, disable))
        .reduce((acc: EcoItem[], curr) => {
            const isDuplicate = acc.some(({ ecoTagLabel }) => ecoTagLabel === curr.ecoTagLabel);
            const hasData =
                curr.ecoBadge || curr.ecoBadgeUrl || curr.ecoTag || curr.ecoTagLabel || curr.efficiencyLabelConfig.show;
            if (!isDuplicate && hasData) acc.push(curr);
            return acc;
        }, []);
