import React, { FC, memo, useCallback, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { GradeBodyTypeType } from "../../../../../utils/types/Model";
import EngineSpecs from "./EngineSpecs";
import {
    MotorizationItemType,
    getMotorizations,
    getUniqueMotorizationId,
    getMotorizationData,
} from "../../../../../utils/gradeUtils";
import { trackEngineOptionsClose, trackEngineOptionsOpen } from "../../../../../utils/tracking";
import styles from "./EngineInfo.module.scss";
import { useGradeSelectorSelector } from "../../../../../redux/hooks";
import Collapse from "../../../../../../shared-components/css-modules/toyota/molecules/Collapse";
import EngineSelect from "./EngineSelect";
import { GradeSelectorDispatchType } from "../../../../../redux/store";
import { getUniqueGradeBodyTypeId } from "../../../../../../../../common-deprecated/features/apheleia/utils/aphUtils";
import { setSelectedMotorization as selectMotorization } from "../../../../../redux/slices/GradeSelectorModelSlice";

const Specs: FC<{ gradeBodyType: GradeBodyTypeType }> = ({ gradeBodyType }) => {
    const dispatch = useDispatch<GradeSelectorDispatchType>();

    const modelId = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.id);
    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorFilters);

    const [isOpen, setIsOpen] = useState(false);

    const motorizations = getMotorizations(gradeBodyType, activeFilters);

    const hasMultipleMotorizations = motorizations.length > 1;

    const selectedMotorization = getMotorizationData(gradeBodyType);
    const uniqueBodyTypeId = getUniqueGradeBodyTypeId(
        gradeBodyType.grade.id,
        gradeBodyType.bodyType.id,
        gradeBodyType.submodel?.id,
    );

    const onOpenChange = useCallback(
        (open: boolean) => {
            if (open) {
                trackEngineOptionsOpen(gradeBodyType.grade.name);
            } else {
                trackEngineOptionsClose(gradeBodyType.grade.name);
            }
        },
        [gradeBodyType],
    );

    const selectMotorizationCallback = useCallback(
        (motorization: MotorizationItemType) => {
            dispatch(
                selectMotorization({
                    uniqueBodyTypeId,
                    motorization: {
                        engineId: motorization.engine.id,
                        transmissionId: motorization.transmission.id,
                        wheeldriveId: motorization.wheeldrive.id,
                    },
                }),
            );
        },
        [dispatch, uniqueBodyTypeId],
    );

    return (
        <div className={styles.motorization}>
            <Collapse
                disabled={!hasMultipleMotorizations}
                open={isOpen}
                onClick={() => hasMultipleMotorizations && setIsOpen(!isOpen)}
                onChange={() => onOpenChange(isOpen)}
                className={clsx(styles.collapse, isOpen && styles.open, !hasMultipleMotorizations && styles.disabled)}
                summary={<EngineSelect props={{ motorization: selectedMotorization, modelId, summary: true }} />}
            >
                {motorizations.map((motorization) => {
                    const id = getUniqueMotorizationId(
                        motorization.engine,
                        motorization.transmission,
                        motorization.wheeldrive,
                    );
                    return (
                        <EngineSelect
                            key={id}
                            props={{
                                motorization,
                                modelId,
                                setSelectedMotorization: selectMotorizationCallback,
                                active:
                                    getUniqueMotorizationId(
                                        selectedMotorization.engine,
                                        selectedMotorization.transmission,
                                        selectedMotorization.wheeldrive,
                                    ) === id,
                                showSpecs: true,
                            }}
                        />
                    );
                })}
            </Collapse>

            <EngineSpecs
                showPrice
                engine={selectedMotorization.engine}
                transmission={selectedMotorization.transmission}
                wheeldrive={selectedMotorization.wheeldrive}
                selectedColourId={gradeBodyType.selectedColourId}
            />
        </div>
    );
};

export default memo(Specs);
