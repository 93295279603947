import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FinanceCarItemType } from "../../../../../common-deprecated/features/apheleia/utils/types/Finance";
import { RequestStatus, RequestStatusEnum } from "../../../../../common-deprecated/types/CommonTypes";

export type GradeSelectorFinanceType = {
    state: RequestStatus;
    cars: Record<string, FinanceCarItemType>;
};

export const gradeSelectorFinanceSlice = createSlice({
    name: "gradeSelectorFinance",
    initialState: {
        state: RequestStatusEnum.Idle,
        cars: {},
    } as GradeSelectorFinanceType,
    reducers: {
        initFinance(state, action: PayloadAction<GradeSelectorFinanceType["cars"]>) {
            state.state = RequestStatusEnum.Complete;
            state.cars = action.payload;
        },
        setFinanceState(state, action: PayloadAction<GradeSelectorFinanceType["state"]>) {
            state.state = action.payload;
        },
    },
});

export const { initFinance, setFinanceState } = gradeSelectorFinanceSlice.actions;
